import React from "react";
import { Select, Radio, Popover, Button } from "antd";
import moment from "moment";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import "./index.less";

const RangePicker = Loader.loadBaseComponent("RangePicker");
const Option = Select.Option;
@Decorator.businessProvider("residentPerson", "flowPerson")
@observer
class AlarmHeaderFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dateBegin: undefined,
      dateEnd: undefined,
      showDate: false,
      SpopHoverType: false,
      popShow: false,
      minDate: moment(new Date()).valueOf() - 2592000000, //限制30天时间
      id: Math.random(),
      searchDataOne:toJS(props.residentPerson.searchOption),
      searchDataTwo:toJS(props.residentPerson.searchOptionUnappear),
      searchDataThr:toJS(props.residentPerson.allSearchOption),
      searchDataFou:toJS(props.flowPerson.FloatsearchOption),
      searchDataFiv:toJS(props.flowPerson.FloatsearchOptionUnappear),
      searchDataSix:toJS(props.flowPerson.allFloatSearchOption),
    };
    this.refCommunity=React.createRef()
  }
  /**更新选择框中选中的数据 */
  freshSearchData = () => {
    const { residentPerson,flowPerson } = this.props;
    this.setState({
      searchDataOne:toJS(residentPerson.searchOption),
      searchDataTwo:toJS(residentPerson.searchOptionUnappear),
      searchDataThr:toJS(residentPerson.allSearchOption),
      searchDataFou:toJS(flowPerson.FloatsearchOption),
      searchDataFiv:toJS(flowPerson.FloatsearchOptionUnappear),
      searchDataSix:toJS(flowPerson.allFloatSearchOption),
    })
  }
   componentDidMount(){
    const { residentPerson,flowPerson } = this.props;
     setTimeout(() => {
      this.setState({
        searchDataOne:toJS(residentPerson.searchOption),
        searchDataTwo:toJS(residentPerson.searchOptionUnappear),
        searchDataThr:toJS(residentPerson.allSearchOption),
        searchDataFou:toJS(flowPerson.FloatsearchOption),
        searchDataFiv:toJS(flowPerson.FloatsearchOptionUnappear),
        searchDataSix:toJS(flowPerson.allFloatSearchOption),
      })
     },1000)
  }
  // 标签筛选
  handleTagSort = value => {
    const { residentPerson, onTypeChange, activeKey, flowPerson } = this.props;
    this.popCancel();
    if (value == "") {
      residentPerson.editSearchData({ offset: 0, tagCodes: [] }, activeKey);
      flowPerson.editSearchData({ offset: 0, tagCodes: [] }, activeKey);
      onTypeChange();
      this.freshSearchData();
      return;
    }
    residentPerson.editSearchData({ offset: 0, tagCodes: [value] }, activeKey);
    flowPerson.editSearchData({ offset: 0, tagCodes: [value] }, activeKey);
    onTypeChange();
    this.freshSearchData();
  };
  /**时间筛选 */
  chooseTime = (activeKey, e) => {
    let { onTypeChange, residentPerson, flowPerson } = this.props;
    let value = e.target.value;
    if (value == 2) {
      residentPerson.editSearchData({ peroidType: -1 }, activeKey);
      flowPerson.editSearchData({ peroidType: -1 }, activeKey);
      this.freshSearchData();
      return;
    } else {
      this.setState({
        dateBegin: null,
        dateEnd: null,
        popShow:false
      });
    }
    residentPerson.editSearchData(
      {
        startTime: undefined,
        endTime: undefined,
        offset: 0,
        peroidType: value - 0
      },
      activeKey
    );
    flowPerson.editSearchData(
      {
        startTime: undefined,
        endTime: undefined,
        offset: 0,
        peroidType: value - 0
      },
      activeKey
    );
    onTypeChange();
    this.freshSearchData();
  };
  timeChange = (type, value) => {
    let { residentPerson, activeKey, flowPerson } = this.props;
    let { dateBegin } = this.state;
    let startTime = dateBegin;
    let endTime = null;
    if (type === "startTime") {
      startTime = value;
      this.setState({ dateBegin: startTime });
    } else {
      endTime = value;
      this.setState({ dateEnd: endTime });
    }
    if (endTime === null) {
      endTime = moment(new Date()).valueOf();
    }
    residentPerson.editSearchData(
      { offset: 0, peroidType: -1, endTime: endTime, startTime: startTime },
      activeKey
    );
    flowPerson.editSearchData(
      { offset: 0, peroidType: -1, endTime: endTime, startTime: startTime },
      activeKey
    );
  };
  popSubmit = () => {
    const { onTypeChange } = this.props;
    onTypeChange(true);
    this.setState({
      popShow: false
    });
  };
  popCancel = () => {
    if (this.state.popShow) {
      this.setState({
        popShow: false
      });
    }
  };
  popChange = () => {
    this.setState({
      popShow: true
    });
  };
  /**类型选择 */
  handleTypeChange = value => {
    let { activeKey, residentPerson, onTypeChange, flowPerson } = this.props;
    this.popCancel();
    residentPerson.editSearchData({ sort: [value], offset: 0 }, activeKey);
    flowPerson.editSearchData({ sort: [value], offset: 0 }, activeKey);
    onTypeChange();
    this.freshSearchData();
  };
  /**关注选择 */
  handleFocuSearch = value => {
    let { activeKey, residentPerson, flowPerson, onTypeChange } = this.props;
    this.popCancel();
    residentPerson.editSearchData({ isFocus: value, offset: 0 }, activeKey);
    flowPerson.editSearchData({ isFocus: value, offset: 0 }, activeKey);
    onTypeChange();
    this.freshSearchData();
  };
  /**日期选择框显示的问题 */
  componentWillReceiveProps(nextProps) {
    if (this.props.activeKey !== nextProps.activeKey) {
       this.setState({
         popShow:false
       })
    }
  }
  handleActiveSearch = () => {
    let {searchDataOne,searchDataTwo,searchDataThr,searchDataFou,searchDataFiv,searchDataSix}=this.state;
    let {isCover,activeKey}=this.props;
    let data=!isCover?
    ( activeKey == 1
      ? toJS(searchDataFou)
      : activeKey == 2
      ? toJS(searchDataFiv)
      : toJS(searchDataSix))
     :( activeKey == 1
        ? toJS(searchDataOne)
        : activeKey == 2
        ? toJS(searchDataTwo)
        : toJS(searchDataThr))
        return data
  }
  render() {
    let { dateBegin, dateEnd, popShow, minDate, id } = this.state;
    let {
      activeKey,
      Loadtype,
      isCover
    } = this.props;
    let Tagarr = [
      ...Dict.map.personnelAttr,
      ...Dict.map.behaviorAttr,
      ...Dict.map.aidBehaviorCode,
      ...Dict.map.fatAndThin,
      ...Dict.map.gait,
      ...Dict.map.height,
      ...Dict.map.identity,
      ...Dict.map.aidBehavior,
      ...Dict.map.appearPlace
    ].filter(v => v.value!='118703')
    let SearchData = this.handleActiveSearch();
    return (
      <div className="community-another-alarm_header_filter">
        <Select
          key={id}
          dropdownClassName="header_filter_select_time_downwrap"
          className="header_filter_time_select"
          style={{ width: 148 }}
          value={SearchData.tagCodes[0] ? SearchData.tagCodes[0] : ""}
          onChange={this.handleTagSort}
          defaultValue={""}
          size='small'
        >
          <Option value={""} className="community-option-size">
            全部标签
          </Option>
          {Tagarr.map(v => (
            <Option value={v.value} className="community-option-size">
              {v.value.indexOf("1191") != -1 ? "经常出现" + v.label : v.label}
            </Option>
          ))}
          <Option value={"0"} className="community-option-size">
            其他
          </Option>
        </Select>
        {(!isCover || activeKey == 1) && (
          <Select
            key={id + 0.68345473274}
            dropdownClassName="header_filter_select_type_downwrap"
            className="header_filter_type_select"
            style={{ width: 110 }}
            value={SearchData.sort[0]}
            onChange={this.handleTypeChange}
            defaultValue={"count|desc"}
            size='small'
          >
            {!isCover ? (
              <Option value="count|desc" className="community-option-size">
                按抓拍次数排序
              </Option>
            ) : (
              <Option value="name|desc" className="community-option-size">
                按姓名排序
              </Option>
            )}
            <Option value="recent_time|desc" className="community-option-size">
              按最后抓拍时间排序
            </Option>
          </Select>
        )}
        <Select
          key={id + 0.6358432}
          dropdownClassName="header_filter_select_type_downwrap"
          className="header_filter_type_select anotherwidth"
          style={{ width: "100px !important" }}
          value={SearchData.isFocus}
          onChange={this.handleFocuSearch}
          defaultValue={-1}
          size='small'
        >
          <Option value={-1} className="community-option-size">
            全部
          </Option>
          <Option value={1} className="community-option-size">
            已关注
          </Option>
          <Option value={0} className="community-option-size">
            未关注
          </Option>
        </Select>
        <div ref={this.refCommunity}>
        {(!isCover || activeKey == 1) && (
          <Radio.Group
            className="header_filter_radio"
            defaultValue={0}
            value={SearchData.peroidType != -1 ? SearchData.peroidType : 2}
            buttonStyle="solid"
            onChange={this.chooseTime.bind(this, activeKey)}
            size='small'
          >
            <Radio value={0}>不限</Radio>
            <Radio value={1}>24小时</Radio>
            <Radio value={3}>3天</Radio>
            <Radio value={7}>一周</Radio>
            <Popover
              overlayClassName={`community-radio_poppver ${
                activeKey == Loadtype ? "" : "hidden"
              }`}
              getPopupContainer={() => this.refCommunity.current}
              content={
                <div>
                  <RangePicker
                    onChange={this.timeChange}
                    startTime={dateBegin}
                    endTime={dateEnd}
                    minDate={minDate}
                  />
                  <div className="pop_btn">
                    <Button onClick={this.popCancel}>取消</Button>
                    <Button onClick={this.popSubmit} type="primary">
                      确定
                    </Button>
                  </div>
                </div>
              }
              placement="bottom"
              visible={popShow}
            >
              <span
                className='pop-render'
                onClick={this.popChange}
              >
                <Radio value={2}>自定义</Radio>
              </span>
            </Popover>
          </Radio.Group>
        )}
           </div>
      </div>
    );
  }
}

export default AlarmHeaderFilter;
